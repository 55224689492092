import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export const HeaderSocial = () => {
  const data = useStaticQuery(graphql`
    query HeaderSocialQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "site-settings" } }) {
        frontmatter {
          facebookUrl
          twitterUrl
          logoSubtitle
          homepageHeading
        }
      }
    }
  `)

  const frontmatter = data.markdownRemark.frontmatter

  return (
    <div className="site-head-right">
      <div className="social-links">
        {frontmatter.facebookUrl && (
          <a
            href={frontmatter.facebookUrl}
            title="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        )}

        {frontmatter.twitterUrl && (
          <a
            href={frontmatter.twitterUrl}
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        )}
      </div>
    </div>
  )
}
