import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
export const HeaderLogo = () => {
  const data = useStaticQuery(graphql`
    query HeaderLogoQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "site-settings" } }) {
        frontmatter {
          facebookUrl
          twitterUrl
          logoSubtitle
          homepageHeading
        }
      }
      file(relativePath: { eq: "kevin-burke-logo.png" }) {
        childImageSharp {
          fixed(width: 140, height: 180) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  const frontmatter = data.markdownRemark.frontmatter

  return (
    <div className="site-head-center">
      <Link className="site-head-logo" to={`/`}>
        <Img
          fixed={data.file.childImageSharp.fixed}
          alt="Kevin Burke SEO Logo"
        />
        {/* <img
          // src="/images/site/kevin-burke-logo.svg"
          src="/images/site/kevin-burke-logo.png"
          alt="Kevin Burke SEO Logo"
        /> */}
        {frontmatter.logoSubtitle}
      </Link>
    </div>
  )
}
